/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2021-10-27 09:34:11
 * @modify date 2021-10-27 09:34:11
 * @desc [description]
 */

// export const UID = "token";
export const UID = "DR_TOKEN";
export const UEMAIL = "UEMAIL";
export const MYQR = "MYQR";
export const DR_ORDER_ID = "DR_ORDER_ID";
export const ACCOUNT = "ACCOUNT";

