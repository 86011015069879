import { FilterState } from 'types/States';
import { FilterAction } from 'types/Actions';

export const defaultFilterState: FilterState = {
	service: {},
	time: ''
};

export default function filterReducer(state: FilterState, action: FilterAction) {
	switch (action.type) {
		case 'NOTFILTER': {
			return defaultFilterState;
		}
		case 'FILTER': {
			return action.value || state;
		}
		default: {
			throw new Error(`unexpected action.type: ${action.type}`);
		}
	}
}
