import { OrderState } from 'types/States';
import { OrderAction } from 'types/Actions';

export const defaultOrderState: OrderState = {
	option: {},
	style: {},
	detailMachine: {},
	store: {},
	totalPrice: 0,
	timeWash: 0
};

export default function orderReducer(state: OrderState, action: OrderAction) {
	switch (action.type) {
		case 'NOTORDER': {
			return defaultOrderState;
		}
		case 'ORDER': {
			return action.value || state;
		}
		default: {
			throw new Error(`unexpected action.type: ${action.type}`);
		}
	}
}
