import React from 'react';
import { default as AccountStoreProvider } from './accountStore';//Store State
import { default as AfterLoginStoreProvider } from './afterLoginStore';//Store State
import { default as OrderStoreProvider } from './orderStore';//Store State
import { default as FilterStoreProvider } from './filterStore';//Store State
import { default as HistoryStoreProvider } from './historyStore';//Store State
import { default as DryCleaningStore } from './dryCleaningStore';//Store State

export default function GlobalStore({
  children,
}: {
  children: React.ReactElement;
}) {
  return (
    <AfterLoginStoreProvider>
      <AccountStoreProvider>
        <HistoryStoreProvider>
          <OrderStoreProvider>
            <FilterStoreProvider>
              <DryCleaningStore>
                {children}
              </DryCleaningStore>
            </FilterStoreProvider>
          </OrderStoreProvider>
        </HistoryStoreProvider>
      </AccountStoreProvider>
    </AfterLoginStoreProvider>
  );
}
