import React, {
  createContext,
  useReducer,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { OrderAction } from 'types/Actions';
import { OrderState } from 'types/States';
import { OrderReducer } from 'types/CustomHooks';
import orderReducer, { defaultOrderState } from './reducer';

/** Create OrderState Context */
export const OrdersState = createContext<OrderState>(
  defaultOrderState
);

/** Create Order Context */
export const OrdersAction = createContext<{
  orderDispatcher: Dispatch<OrderAction>;
  setOrder: Dispatch<SetStateAction<any>>;
}>({ orderDispatcher: () => { }, setOrder: () => { } });

/** Create OrderStoreProvider */
function OrderStoreProvider({ children }: { children: React.ReactElement }) {
  const [orderState, orderDispatcher] = useReducer<OrderReducer>(
    orderReducer,
    defaultOrderState,
  );
  const [isOrder, setOrder] = useState<any>(false);

  useEffect(() => {
    orderDispatcher({
      type: 'ORDER',
      value: isOrder,
    });
  }, [isOrder]);

  return (
    <OrdersAction.Provider value={{ orderDispatcher, setOrder }}>
      <OrdersState.Provider value={orderState}>
        {children}
      </OrdersState.Provider>
    </OrdersAction.Provider>
  );
}
export default OrderStoreProvider;
