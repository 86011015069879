import { DryCleaningState } from 'types/States';
import { DryCleaningAction } from 'types/Actions';

export const defaultDryCleaningState: DryCleaningState = {
	type: '',
	typesReqBodies: []
};

export default function dryCleaningReducer(state: DryCleaningState, action: DryCleaningAction) {
	switch (action.type) {
		case 'NOTOPTION': {
			return defaultDryCleaningState;
		}
		case 'OPTION': {
			let data = state.typesReqBodies.length > 0 ? state.typesReqBodies : [];
			// let data: never[] = [];
			let valueAction:any = action.value;
			if (valueAction) {
				let valueType:any = valueAction?.type;
				let check = data && data.findIndex((el: any) => el.type === valueType);
				check > -1 ? data[check] = valueAction : data.push(valueAction)
				// typeAdd = 'ADD'
			}

			// console.log('state :>> ', state);
			// console.log('action :>> ', action);
			return {
				...state,
				typesReqBodies: data,
			};
		}
		default: {
			throw new Error(`unexpected action.type: ${action?.type}`);
		}
	}
}
