import { createGlobalStyle } from 'styled-components';
import { theme } from 'styled-tools';
import {
  NotoSansCJKkrBlack,
  NotoSansCJKkrBold,
  NotoSansCJKkrDemiLight,
  NotoSansCJKkrLight,
  NotoSansCJKkrMedium,
  NotoSansCJKkrRegular,
  NotoSansCJKkrThin,
} from 'assets/font/font';

const GlobalStyles = createGlobalStyle`
  @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
  @import url('https://fonts.googleapis.com/css?family=Gothic+A1|Noto+Sans+KR&display=swap');
  // @font-face {
  //   font-family: 'S-CoreDream-8Heavy';
  //   src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-8Heavy.woff') format('woff2');
  //   font-weight: normal;
  //   font-style: normal;
  //   font-display: swap;
  // }
  @font-face {
    font-family: 'NotoSansCJKkrBlack';
    src: url(${NotoSansCJKkrBlack}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'NotoSansCJKkrBold';
    src: url(${NotoSansCJKkrBold}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'NotoSansCJKkrDemiLight';
    src: url(${NotoSansCJKkrDemiLight}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'NotoSansCJKkrLight';
    src: url(${NotoSansCJKkrLight}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'NotoSansCJKkrMedium';
    src: url(${NotoSansCJKkrMedium}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'NotoSansCJKkrRegular';
    src: url(${NotoSansCJKkrRegular}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'NotoSansCJKkrThin';
    src: url(${NotoSansCJKkrThin}) format('woff2');
    font-display: swap;
  }
  * {
    margin: 0;
    font-family: 'NotoSansCJKkrDemiLight','Spoqa Han Sans', 'Noto Sans KR', 'Gothic A1', sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    font-size: 10px;
  }
  body {
    height: 100%;
  }
  a, button {
    text-decoration: none;
    cursor: pointer;
  }

  h1 {
    ${theme('fontStyle.h3')}
  }

  h2 {
    ${theme('fontStyle.h4')}
  }

  h2 {
    ${theme('fontStyle.h5')}
  }

  h3 {
    ${theme('fontStyle.subtitle1')}
  }

  h4 {
    ${theme('fontStyle.subtitle2')}
  }

  h5 {
    ${theme('fontStyle.body1')}
  }

  p {
    ${theme('fontStyle.body2')}
  }
  .hljs{
    background: #000000;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  outline : none;
  img {
    image-rendering: -webkit-optimize-contrast;
  }

  .Launcherstyled__Wrapper-ch-front__sc-oef45p-0{
    opacity: 0;
    pointer-events: none;
    z-index: -999999 !important;
    height: 0 !important;
    width: 0 !important;
  }
`;

export default GlobalStyles;

// @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
// @import url('https://fonts.googleapis.com/css?family=Gothic+A1|Noto+Sans+KR&display=swap');
// @font-face {
//   font-family: 'S-CoreDream-8Heavy';
//   src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-8Heavy.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'NotoSansCJKkrBlack';
//   src: url(${NotoSansCJKkrBlack}) format('woff');
// }
// @font-face {
//   font-family: 'NotoSansCJKkrBold';
//   src: url(${NotoSansCJKkrBold}) format('woff');
// }
// @font-face {
//   font-family: 'NotoSansCJKkrDemiLight';
//   src: url(${NotoSansCJKkrDemiLight}) format('woff');
// }
// @font-face {
//   font-family: 'NotoSansCJKkrLight';
//   src: url(${NotoSansCJKkrLight}) format('woff');
// }
// @font-face {
//   font-family: 'NotoSansCJKkrMedium';
//   src: url(${NotoSansCJKkrMedium}) format('woff');
// }
// @font-face {
//   font-family: 'NotoSansCJKkrRegular';
//   src: url(${NotoSansCJKkrRegular}) format('woff');
// }
// @font-face {
//   font-family: 'NotoSansCJKkrThin';
//   src: url(${NotoSansCJKkrThin}) format('woff');
// }
