import React, {
  createContext,
  useReducer,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { HitoryAction } from 'types/Actions';
import { HistoryState } from 'types/States';
import { HistoryReducer } from 'types/CustomHooks';
import historyReducer , { defaulHistoryState } from './reducer';


/** Create OrderState Context */
export const HistorysState = createContext<HistoryState>(
  defaulHistoryState
);

/** Create History Context */
export const HistoryAction = createContext<{
  historyDispatcher: Dispatch<HitoryAction>;
  setIsHistory: Dispatch<SetStateAction<any>>;
}>({ historyDispatcher: () => { }, setIsHistory: () => { } });

/** Create HistoryStoreProvider */
function HistoryStoreProvider({ children }: { children: React.ReactElement }) {
  const [historyState, historyDispatcher] = useReducer<HistoryReducer>(
    historyReducer,
    defaulHistoryState,
  );
  const [isHistory, setIsHistory] = useState<any>(false);
  useEffect(() => {
    historyDispatcher({
      type: 'HISTORY',
      value: isHistory,
    });
  }, [isHistory]);

  return (
    <HistoryAction.Provider value={{ historyDispatcher, setIsHistory }}>
      <HistorysState.Provider value={historyState}>
        {children}
      </HistorysState.Provider>
    </HistoryAction.Provider>
  );
}
export default HistoryStoreProvider;
