import React, { useContext, useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  // useHistory,
} from "react-router-dom";

import { ThemeProvider } from "styled-components";
// import { useCookies } from 'react-cookie';
import defaultTheme from "common/style/themes/default";
import Normalize from "common/style/Normalize";
import GlobalStyles from "common/style/GlobalStyle";
import GlobalStoreProvider from "stores";
import { UserAccountState, UserAccountAction } from "stores/accountStore";
import { AfterLoginAction } from "stores/afterLoginStore";
import { getLocalStorage } from "utils/localStorage";
import { UID as UUID } from "utils/types";

import loading from "assets/img/loading.svg";

const Introduce = lazy(() => import("pages/Introduce"));
const Login = lazy(() => import("pages/Login"));
const SignUp = lazy(() => import("pages/SignUp"));
const Home = lazy(() => import("pages/Home"));
const Main = lazy(() => import("pages/Main"));
const Privacy = lazy(() => import("pages/Privacy"));
const Order = lazy(() => import("pages/Order"));
const Payment = lazy(() => import("pages/Payment"));
const ResultCreateUserWpay = lazy(() =>
  import("pages/Payment/ResultCreateUserWpay")
);
const ResultPaymentWpay = lazy(() => import("pages/Payment/ResultPaymentWpay"));
const FindId = lazy(() => import("pages/FindId"));
const CompleteAuthentication = lazy(() => import("pages/FindId/CompleteAuth"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const SetTime = lazy(() => import("pages/SetTime"));
const ConfirmForgotPassword = lazy(() =>
  import("pages/ForgotPassword/confirmNumberPhone")
);
const ChangePassword = lazy(() =>
  import("pages/ForgotPassword/changePassword")
);
const Laundry = lazy(() => import("pages/Laundry"));
const Store = lazy(() => import("pages/Store"));
const MypageAccount = lazy(() => import("pages/Mypage/Account"));
const MypageSetting = lazy(() => import("pages/Mypage/MypageSetting"));
const PushNotice = lazy(() => import("pages/Mypage/PushNoti"));
const MypageCancelMember = lazy(() => import("pages/Mypage/CancelMember"));
const Notice = lazy(() => import("pages/Notice"));
const Grade = lazy(() => import("pages/Mypage/Grade"));
const Level = lazy(() => import("pages/Mypage/Level"));
const History = lazy(() => import("pages/Mypage/History"));
const HistoryDetail = lazy(() => import("pages/Mypage/HistoryDetail"));
const CancelOrder = lazy(() => import("pages/Mypage/CancelOrder"));
const Coupon = lazy(() => import("pages/Mypage/Coupon"));
const Point = lazy(() => import("pages/Mypage/Point"));
const Event = lazy(() => import("pages/Mypage/Event"));
const FAQPage = lazy(() => import("pages/Mypage/FAQ"));
const MypageRegisterCoupon = lazy(() => import("pages/Mypage/RegisterCoupon"));
const MypageRegisterPoint = lazy(() => import("pages/Mypage/RegisterPoint"));
const QrCode = lazy(() => import("pages/QrCode"));
const QrCode2 = lazy(() => import("pages/QrCode2"));
const QrCodeDry = lazy(() => import("pages/QrCodeDry"));
const DryCleaning = lazy(() => import("pages/DryCleaning"));
const DetailDryCleaning = lazy(() => import("pages/DryCleaning/detail"));
const InicisPayment = lazy(() => import("pages/InicisPayment"));
const Sterilization = lazy(() => import("pages/Sterilization"));
const ListStore = lazy(() => import("pages/ListStore"));
const HowToUse = lazy(() => import("pages/HowToUse"));
const DetailUse = lazy(() => import("pages/HowToUse/DetailUse"));
const ServiceDetails = lazy(() => import("pages/ServiceDetails"));

const renderLoader = () => (
  <div className="loading">
    <img className="icon-loading" src={loading} alt="loading" />
  </div>
);
// const { REACT_APP_TEST_UID_TOKEN } = process.env;

const App: React.FC = () => {
  return (
    <GlobalStoreProvider>
      <ThemeProvider theme={defaultTheme}>
        <Normalize />
        <GlobalStyles />
        <Router>
          <Switch>
            <Suspense fallback={renderLoader()}>
              {/* <PublicRoute exact path="/" component={Home} /> */}
              <PublicRoute exact path="/map" component={Home} />
              <PublicRoute exact path="/" component={Main} />
              <PublicRoute exact path="/login" component={Login} />
              <PublicRoute path="/signup" component={SignUp} />
              <PublicRoute path="/findid" component={FindId} />
              <PublicRoute path="/privacy" component={Privacy} />
              <PublicRoute path="/sterilization" component={Sterilization} />
              <PublicRoute path="/list-store" component={ListStore} />
              <PrivateRoute path="/order" component={Order} />
              <PrivateRoute path="/inicis" component={InicisPayment} />
              <PublicRoute path="/paymentsuccess*" component={Payment} />
              <PrivateRoute
                path="/payment/result-create-user-wpay"
                component={ResultCreateUserWpay}
              />
              <PrivateRoute
                path="/payment/result-payment-wpay"
                component={ResultPaymentWpay}
              />
              <PublicRoute
                path="/completeauthentication"
                component={CompleteAuthentication}
              />
              <PublicRoute path="/forgotpassword" component={ForgotPassword} />
              <PrivateRoute path="/settime" component={SetTime} />
              <PublicRoute
                path="/confirm/forgotpassword"
                component={ConfirmForgotPassword}
              />
              <PublicRoute
                path="/changepassword/forgotpassword"
                component={ChangePassword}
              />
              <PrivateRoute path="/laundry" component={Laundry} />
              {/* <PrivateRoute path="/filterstore" component={FilterStore} /> */}
              <PrivateRoute path="/store" component={Store} />
              <PrivateRoute path="/mypage/account" component={MypageAccount} />
              <PrivateRoute path="/mypage/setting" component={MypageSetting} />
              <PrivateRoute path="/push-notice" component={PushNotice} />
              <PrivateRoute
                path="/mypage/cancelmember"
                component={MypageCancelMember}
              />
              <PrivateRoute path="/notice" component={Notice} />
              <PrivateRoute path="/mypage/grade" component={Grade} />
              <PrivateRoute path="/mypage/level" component={Level} />
              <PrivateRoute path="/mypage/history" component={History} />
              <PrivateRoute
                path="/mypage/cancelorder"
                component={CancelOrder}
              />
              <PrivateRoute
                path="/mypage/historydetail"
                component={HistoryDetail}
              />
              <PrivateRoute path="/mypage/coupon" component={Coupon} />
              <PrivateRoute path="/mypage/point" component={Point} />
              <PrivateRoute path="/mypage/event" component={Event} />
              <PrivateRoute
                path="/mypage/registercoupon"
                component={MypageRegisterCoupon}
              />
              <PrivateRoute
                path="/mypage/registerpoint"
                component={MypageRegisterPoint}
              />
              <PrivateRoute path="/mypage/faq" component={FAQPage} />
              <PublicRoute path="/introduce" component={Introduce} />
              <PrivateRoute path="/qrcode" component={QrCode} />
              <PrivateRoute path="/qrcode2" component={QrCode2} />
              <PrivateRoute path="/qrcodedry" component={QrCodeDry} />
              <PrivateRoute path="/drycleaning" component={DryCleaning} />
              <PrivateRoute
                path="/detaildrycleaning"
                component={DetailDryCleaning}
              />
              <PrivateRoute
                path="/how-to-use"
                component={HowToUse}
              />
              <PrivateRoute
                path="/how-to-use-detail/:id"
                component={DetailUse}
              />
              <PublicRoute
                path="/service-details/:id"
                component={ServiceDetails}
              />
              {/* 
            <JoinRoute
              path="/events/:eventId([0-9]+)/register/tickets"
              component={EventJoin}
            />
            {/* <PublicRoute path="*" component={NotFound} /> */}
            </Suspense>
          </Switch>
        </Router>
      </ThemeProvider>
    </GlobalStoreProvider>
  );
};

export default App;

function PublicRoute({
  component: TargetPage,
  ...rest
}: any): React.ReactElement {
  return <Route {...rest} render={(props: any) => <TargetPage {...props} />} />;
}

function PrivateRoute({
  component: TargetPage,
  ...rest
}: any): React.ReactElement {
  // const [cookies] = useCookies(['UID']);
  let ID: any = getLocalStorage(UUID, 0);
  let UID = "";
  if (ID !== null) UID = ID; //UID

  const accountState = useContext(UserAccountState);
  const { setLoginState } = useContext(UserAccountAction);
  // const [isLoginCheck, setIsLoginCheck] = useState(false);
  const { setLoginCallback } = useContext(AfterLoginAction);
  const path = window.location.pathname;

  useEffect(() => {
    setLoginState(true);
  }, [UID, setLoginState]);

  useEffect(() => {
    if (!accountState.isLogin) setLoginCallback(path);
  }, [rest, accountState.isLogin, setLoginCallback, path]);

  if (
    (UID !== "" && UID !== undefined && UID !== null) ||
    accountState.isLogin
  ) {
    return (
      <Route {...rest} render={(props: any) => <TargetPage {...props} />} />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          ID ? <TargetPage {...props} /> : <Redirect to="/introduce" />
        }
      />
    );
  }
}
