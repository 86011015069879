import React, {
  createContext,
  useReducer,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { DryCleaningAction } from 'types/Actions';
import { DryCleaningState } from 'types/States';
import { DryCleaningReducer } from 'types/CustomHooks';
import dryCleaningReducer, { defaultDryCleaningState } from './reducer';


/** Create DryCleaningsState Context */
export const DryCleaningsState = createContext<DryCleaningState>(
  defaultDryCleaningState
);

/** Create DryCleaning Context */
export const DryCleaningsAction = createContext<{
  dryCleaningDispatcher: Dispatch<DryCleaningAction>;
  setDryCleaning: Dispatch<SetStateAction<any>>;
}>({ dryCleaningDispatcher: () => { }, setDryCleaning: () => { } });


/** Create DryCleaningStoreProvider */
function DryCleaningStoreProvider({ children }: { children: React.ReactElement }) {
  const [dryCleaningState, dryCleaningDispatcher] = useReducer<DryCleaningReducer>(
    dryCleaningReducer,
    defaultDryCleaningState
  );

  const [isDryCleaning, setDryCleaning] = useState<any>(null);
  // console.log(`isDryCleaning`, isDryCleaning?.type)
  // console.log(`dryCleaningState`, dryCleaningState)
  useEffect(() => {
    if (isDryCleaning && isDryCleaning.type !== '') {
      dryCleaningDispatcher({
        type: isDryCleaning?.type,
        value: isDryCleaning?.value,
      });
    }

  }, [isDryCleaning]);

  return (
    <DryCleaningsAction.Provider value={{ dryCleaningDispatcher, setDryCleaning }}>
      <DryCleaningsState.Provider value={dryCleaningState}>
        {children}
      </DryCleaningsState.Provider>
    </DryCleaningsAction.Provider>
  );
}
export default DryCleaningStoreProvider;
