import { HistoryState } from 'types/States';
import { HitoryAction } from 'types/Actions';

export const defaulHistoryState: HistoryState = {
	id: 0,
	item: '',

};

export default function historyReducer(state: HistoryState, action: HitoryAction) {
	switch (action.type) {
		case 'NOTHISTORY': {
			return defaulHistoryState;
		}
		case 'HISTORY': {
			return action.value || state;
		}
		default: {
			throw new Error(`unexpected action.type: ${action.type}`);
		}
	}
}
