import React, {
  createContext,
  useReducer,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { AccountAction } from 'types/Actions';
import { AccountState } from 'types/States';
import { AccountReducer } from 'types/CustomHooks';
import accountReducer, { defaultAccountState } from './reducer';

/** Create UserAccountState Context */
export const UserAccountState = createContext<AccountState>(
  defaultAccountState,
);

/** Create UserAccount Context */
export const UserAccountAction = createContext<{
  accountDispatcher: Dispatch<AccountAction>;
  setLoginState: Dispatch<SetStateAction<boolean>>;
}>({ accountDispatcher: () => { }, setLoginState: () => { } });


/** Create AccountStoreProvider */
function AccountStoreProvider({ children }: { children: React.ReactElement }) {
  const [accountState, accountDispatcher] = useReducer<AccountReducer>(
    accountReducer,
    defaultAccountState,
  );
  const [loginState, setLoginState] = useState<any>(false);

  useEffect(() => {
    accountDispatcher({
      type: 'LOGIN',
      value: loginState,
    });
  }, [loginState]);


  return (
    <UserAccountAction.Provider value={{ accountDispatcher, setLoginState }}>
      <UserAccountState.Provider value={accountState}>
        {children}
      </UserAccountState.Provider>
    </UserAccountAction.Provider>
  );
}
export default AccountStoreProvider;
