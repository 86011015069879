import React, {
  createContext,
  useReducer,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { FilterAction } from 'types/Actions';
import { FilterState } from 'types/States';
import { FilterReducer } from 'types/CustomHooks';
import filterReducer, { defaultFilterState } from './reducer';


/** Create FiltersState Context */
export const FiltersState = createContext<FilterState>(
  defaultFilterState
);

/** Create Filter Context */
export const FiltersAction = createContext<{
  filterDispatcher: Dispatch<FilterAction>;
  setFilter: Dispatch<SetStateAction<any>>;
}>({ filterDispatcher: () => { }, setFilter: () => { } });


/** Create FilterStoreProvider */
function FilterStoreProvider({ children }: { children: React.ReactElement }) {
  const [filterState, filterDispatcher] = useReducer<FilterReducer>(
    filterReducer,
    defaultFilterState
  );

  const [isFilter, setFilter] = useState<any>(false);

  useEffect(() => {
    filterDispatcher({
      type: 'FILTER',
      value: isFilter
    });
  }, [isFilter]);

  return (
    <FiltersAction.Provider value={{ filterDispatcher, setFilter }}>
      <FiltersState.Provider value={filterState}>
        {children}
      </FiltersState.Provider>
    </FiltersAction.Provider>
  );
}
export default FilterStoreProvider;
